<template>
	<div>
		<a-spin :spinning="loading">
			<a-row>
				<a-col :span="18">
					<a-button @click="onSearchBtn">
						<template #icon><Icon icon="SearchOutlined"/></template>
						查询</a-button>
					<exportModal class="ui-exportBrn" v-permission="['pc_assets_borrow_export']" :type="type" :searchData="selIdsData" :condition="condition" :role="role" :isAll="1">
						<a-button>
							<template #icon><Icon icon="UploadOutlined"/></template>
							导出</a-button>
					</exportModal>
					<!-- <a-button v-permission="['pc_assets_borrow_export']">
						<template #icon><Icon icon="UploadOutlined"/></template>
						导出</a-button> -->
					<exportModal class="ui-exportBrn" v-permission="['pc_assets_borrow_export']" style="margin-right: 16px;" :type="type" :searchData="searchData" :condition="condition" :role="role" :isAll="0">
						<a-button>
							<template #icon><Icon icon="LogoutOutlined"/></template>
							导出全部</a-button>
					</exportModal>
					<!-- <a-button v-permission="['pc_assets_borrow_export']">
						<template #icon><Icon icon="LogoutOutlined"/></template>
						导出全部</a-button> -->
					<a-button @click="onSearch">
						<template #icon><Icon icon="ReloadOutlined"/></template>
						刷新</a-button>
					<a-button @click="onSort">
						<template #icon><Icon icon="OrderedListOutlined"/></template>
						排序</a-button>
				</a-col>
			</a-row>
			
			<div v-show="isSearch">
				<a-form class="label-width ui-form" ref="formRef" :model="formModal" layout="inline">
					<a-form-item label="资产编号" class="ui-form__item">
						<a-row>
							<a-col :span="11">
								<a-input v-model:value="formModal.startCode"></a-input>
							</a-col>
							<a-col :span="2" class="ui-form__middle">到</a-col>
							<a-col :span="11">
								<a-input v-model:value="formModal.endCode"></a-input>
							</a-col>
						</a-row>
					</a-form-item>
					<a-form-item label="价值区间" class="ui-form__item">
						<a-row>
							<a-col :span="11">
								<a-input v-model:value="formModal.starPrice"></a-input>
							</a-col>
							<a-col :span="2" class="ui-form__middle">到</a-col>
							<a-col :span="11">
								<a-input v-model:value="formModal.endPrice"></a-input>
							</a-col>
						</a-row>
					</a-form-item>
					<a-form-item label="资产名称" class="ui-form__item">
						<a-input v-model:value="formModal.assetsName"></a-input>
					</a-form-item>
					<a-form-item label="资产分类" class="ui-form__item">
						<a-cascader v-model:value="formModal.assetsType" :options="$store.state.count.assetClassify" placeholder="请选择资产分类" />
						<!-- <a-select  allow-clear v-model:value="formModal.assetsType" style="width: 190px;">	
							<a-select-option v-for="(item, index) in $store.state.count.assetClassify" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select> -->
					</a-form-item>
					<a-form-item label="借出单位" class="ui-form__item">
						<a-select showSearch optionFilterProp="label" allow-clear v-model:value="formModal.outUnitId" style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id" :label="item.name">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="使用部门" class="ui-form__item">
						<a-select  allow-clear v-model:value="formModal.departmentId" style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.departmentList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<!-- <a-form-item label="使用人" class="ui-form__item">
						<a-select v-model:value="formModal.userId" allow-clear style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.userList" :key="item.id" :value="item.id">{{ item.username }}</a-select-option>
						</a-select>
					</a-form-item> -->
					<a-form-item label="借入时间段" class="ui-form__item">
						<a-range-picker show-time @change="onChangeLend" :value="clearLendTime"/>
					</a-form-item>
					<a-form-item label="归还时间段" class="ui-form__item">
						<a-range-picker show-time @change="onChangeReturn" :value="clearReturnTime"/>
					</a-form-item>
					<a-form-item class="ui-form__item">
						<div style="text-align: center;">
							<a-button @click="onReset">重置</a-button>
							<a-button type="primary" @click="onSearch">搜索</a-button>
						</div>
					</a-form-item>
				</a-form>
			</div>
			
			<div v-show="isSort" class="ui-form" style="padding-bottom: 20px;">
				<a-row style="align-items: center;">
					<a-col :span="18">
						<a-checkbox-group v-model:value="sortList">
							<a-checkbox :value="'assetsName'">资产名称</a-checkbox>
							<a-checkbox :value="'assetsNum'">资产编号</a-checkbox>
							<a-checkbox :value="'price'">价值</a-checkbox>
							<a-checkbox :value="'time'">借入时间</a-checkbox>
							<a-checkbox :value="'returnTime'">预计归还时间</a-checkbox>
							<a-checkbox :value="'outUnit'">借出单位</a-checkbox>
						</a-checkbox-group>
					</a-col>
					<a-col :span="6" style='text-align: right;'>
						<a-button type="primary" @click="onSortClick('asc')">升序</a-button>
						<a-button type="primary" @click="onSortClick('desc')">降序</a-button>
						<a-button type="primary" @click="onResetSortClick()">重置</a-button>
					</a-col>
				</a-row>
			</div>
			
			<div style="margin-top: 20px;">
				<c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun" rowSelectionType="checkbox" :searchData="searchData" rowKey="id" @rowSelected="rowSelected" :scroll="{ x: 1500 }" bordered draggable>
						<template #bodyCell="{ column, record, index, pagination }">
							<template v-if="column.key === 'index'">
								{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
							</template>
							<template v-if="column.key === 'time'">
								{{ transDateTime(record.time) }}
							</template>
							<template v-if="column.key === 'returnTime'">
								{{ transDateTime(record.returnTime) }}
							</template>
							<template v-if="column.key === 'action'">
								<a-button v-if="record.isReturn !== 1 && record.status == 1" v-permission="['pc_assets_borrow_return']" type="link" size="small" @click="onReturn(record)">归还</a-button>
								<a-button v-if="record.status !== 1" type="link" size="small" v-permission="['pc_jobs_todo_borrow']" @click="onConfirm(record)">借入确认</a-button>
							</template>
							<template v-if="column.key === 'action' && record.isReturn === 1">
								<a-button type="link" size="small">-</a-button>
							</template>
							<template v-if="column.key === 'count'">
								<span>小计</span>
							</template>
						</template>
				</c-Table>
				<!-- <a-table rowKey="id" class="table"
					:columns="columns" 
					:dataSource="list" 
					:pagination="pagination" bordered 
					size="small"
					:scroll="{ x: 1500 }"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.time) }}
						</template>
						<template v-if="column.key === 'returnTime'">
							{{ transDateTime(record.returnTime) }}
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-if="record.isReturn !== 1 && record.status == 1" v-permission="['pc_assets_borrow_return']" type="link" size="small" @click="onReturn(record)">归还</a-button>
							<a-button v-if="record.status !== 1" type="link" size="small" @click="onConfirm(record)">确认</a-button>
						</template>
						<template v-if="column.key === 'action' && record.isReturn === 1">
							<a-button type="link" size="small">-</a-button>
						</template>
						<template v-if="column.key === 'count'">
							<span>小计</span>
						</template>
					</template>
				</a-table> -->
			</div>
		</a-spin>
		<inModal ref="inModalRef" @refresh="onReset"></inModal>
	</div>
</template>

<script>
	import { getBorrowOrLendListByType, getReturn, getConfirmBorrowOrLend } from '@/service/modules/property.js';
	import { Icon } from '@/components/icon/icon.js';
	import cTable from '@/components/cTable/index.vue';
	import exportModal from '@/components/exportModal/exportModal.vue';
	import inModal from './components/inModal.vue';
	export default {
		components: { Icon, cTable, exportModal, inModal },
		data() {
			return {
				loading: false,
				isSearch: false,
				isSort: false,
				isExpand: false,
				searchData: {},
				formModal: {
					type: 1,
				},
				clearLendTime: [],
				clearReturnTime: [],
				getDataFun: getBorrowOrLendListByType,
				list: [],
				type: 'leadAndBorrowStrategy', // 导出类型
				role: 0, // 导出角色
				condition: '1', // 导出条件
				selIdsData: {},
				sortList:[],
				columns: [{
					title: '行号',
					align: 'center',
					key: 'index'
				}, {
					title: '资产分类',
					align: 'center',
					dataIndex: "assetsType"
				}, {
					title: '资产编号',
					align: 'center',
					dataIndex: "assetsNum"
				}, {
					title: '资产名称',
					align: 'center',
					dataIndex: "assetsName"
				}, {
					title: '价值',
					align: 'center',
					dataIndex: "price"
				}, {
					title: '借出单位',
					align: 'center',
					dataIndex: "outUnit"
				}, {
					title: '预计归还时间',
					align: 'center',
					key: 'returnTime',
					// dataIndex: "returnTime"
				}, {
					title: '使用部门',
					align: 'center',
					dataIndex: 'departmentName',
					// dataIndex: "time"
				}, {
					title: '使用人',
					align: 'center',
					dataIndex: 'user',
					// dataIndex: "time"
				}, {
					title: '存放地',
					align: 'center',
					dataIndex: 'address',
					// dataIndex: "time"
				}, {
					title: '借入时间',
					align: 'center',
					key: 'time',
					// dataIndex: "time"
				}, {
					title: '操作',
					align: 'center',
					key: 'action',
					fixed: 'right',
					width: 120
				}],
			}
		},
		created() {
			// this.getData()
			this.onSearch()
		},	
		methods: {
			// async getData() {
			// 	this.loading = true;
			// 	try {
			// 		let ret = await getBorrowOrLendListByType({
			// 			page: this.pagination.current,
			// 			pageSize: this.pagination.pageSize,
			// 			type: 1,
			// 			...this.searchData
			// 		});
			// 		this.loading = false;
			// 		if (ret.code === 200) {
			// 			console.log("borrLit",ret)
			// 			this.list = ret.data.list;
			// 			this.pagination.total = ret.data.totalCount;
			// 		}
			// 	} catch(e) {
			// 		this.loading = false;
			// 	}
			// },
			onReset() {
				this.$refs.formRef.resetFields();
				this.formModal = {
					type: 1,
				}
				this.clearLendTime = []
				this.clearReturnTime = []
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formModal));
				this.searchData.assetsType = this.searchData.assetsType ? this.searchData.assetsType[this.searchData.assetsType.length - 1] : undefined;
				// this.pagination.current = 1;
				// console.log("sear", this.searchData,this.formModal)
				// this.getData();
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onSort() {
				this.isSort = !this.isSort;
			},
			onConfirm(item) { // 确认借入资产
				this.$refs.inModalRef.open(item);
				// this.$confirm({
				// 	title: '提示',
				// 	content: "确定借入吗？",
				// 	onOk: async ()=> {
				// 		this.loading = true;
				// 		try {
				// 			let ret = await getConfirmBorrowOrLend({
				// 				id: item.id
				// 			})
				// 			this.loading = false;
				// 			if (ret.code === 200) {
				// 				this.$message.success('确认成功');
				// 				// this.onSearch();
				// 				this.$nextTick(() => {
				// 					this.$refs.cTable.toQuery();
				// 				})
				// 			}
				// 		} catch(e) {
				// 			this.loading = false;
				// 		}
				// 	}
				// })
			},
			onReturn(item) { // 归还资产
				this.$confirm({
					title: '提示',
					content: "确定归还吗？",
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await getReturn({
								id: item.id
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								// this.getData();
								this.$nextTick(() => {
									this.$refs.cTable.toQuery();
								})
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			rowSelected(item) {
				this.selIdsData.ids = item.keys.join(',')
			},
			onChangeLend(date, dateString) { // 借入时间变化的回调
			  this.clearLendTime = date
			  let starDate = new Date(dateString[0])
			  let endDate = new Date(dateString[1])
			  // console.log(starDate.valueOf(), endDate.valueOf());
			  this.formModal.startTime = starDate.valueOf()/1000
			  this.formModal.endTime = endDate.valueOf()/1000
			},
			onChangeReturn(date, dateString) { // 归还时间变化的回调
			  this.clearReturnTime = date
			  let starDate = new Date(dateString[0])
			  let endDate = new Date(dateString[1])
			  // console.log(starDate.valueOf(), endDate.valueOf());
			  this.formModal.returnStarTime = starDate.valueOf()/1000
			  this.formModal.returnEndTime = endDate.valueOf()/1000
			},
			onSortClick(type){
				let list = [];
				this.sortList.forEach(element => {
					list.push({key:element,sort:type});
				});
				this.formModal.sort = list;
				console.log(this.formModal);
				this.onSearch();
			},
			onResetSortClick(){
				this.sortList = [];
				this.formModal.sort = undefined;
				this.onSearch();
			}
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
	.ui-exportBrn {
		margin-left: 16px;
	}
</style>
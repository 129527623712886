<template>
	<div>
		<a-modal v-model:visible="visible" width="700px" @cancel="close">
			<template #title>
				<modalHeader title="借入资产"></modalHeader>
			</template>
			<template #footer>
				<a-button @click="close">取消</a-button>
				<a-button @click="onSubmit" type="primary">借入确认</a-button>
			</template>
			
			<a-form ref="form" :model="formModal" :label-col="{ span: 4 }" :wrapper-col="{ span: 17 }">
				<a-form-item name="departmentId" label="操作人部门" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-select @select="getSearchUserList"  allow-clear v-model:value="formModal.departmentId" placeholder="请选择操作人部门">
						<a-select-option v-for="(item, index) in $store.state.count.departmentList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item name="userId" label="使用人" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-select  allow-clear v-model:value="formModal.userId" placeholder="请选择使用人">
						<a-select-option v-for="(item, index) in depUserList" :key="item.id" :value="item.id">{{ item.nickname }}</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item name="place" label="存放地" :rules="[{required: true,message:'必填项不允许为空'}]">
					<a-input v-model:value="formModal.place" placeholder="请输入存放地点"></a-input>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
	import { userList } from '@/service/modules/system.js';
	import { applyTransfer, getAssetsDetailSearch, postConfirmTransfer,getConfirmBorrowOrLend } from '@/service/modules/property.js';
	import filesUpload from '@/components/upload/filesUpload.vue';
	import modalHeader from '@/components/modalHeader/index.vue';
	import assetsSelect from '@/components/assetsSelect/index.vue';
	export default {
		components: { filesUpload, modalHeader, assetsSelect },
		data() {
			return {
				visible: false,
				assetsData: {},
				formModal: {},
				depUserList: [], // 对应部门用户
				fileList: []
			}
		},
		methods: {
			async getData(item) {
				this.loading = true;
				try {
					let ret = await getAssetsDetailSearch({
						nameOrCode: item,
					});
					this.loading = false;
					if (ret.code === 200) {
						this.assetsData = ret.data
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onSubmit() {
				this.$refs.form.validate().then(async () => {
					let postData = JSON.parse(JSON.stringify(this.formModal));
					this.loading = true;
					try {
						let ret = await getConfirmBorrowOrLend(postData);
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('借入成功');
							this.$emit('refresh');
							this.close();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			async getSearchUserList(value) {
				this.depUserList = []
				this.formModal.userId = undefined
				let ret = await userList({
					depId: value
				});
				if (ret.code === 200) {
					this.depUserList = ret.data.list
				}
			},
			open(e) {
				// if (e && e.skipId) { // 从待办跳转
				// 	// this.getData(e.skipId)
				// 	console.log("待办处")
				// 	this.formModal.id = e.skipId
				// } else if(e && e.id){
				// 	// this.getData(e.id)
				// 	this.formModal.id = e.id
				// 	this.formModal.place = e.address
				// }
				if(e) {
					this.formModal.id = e.id
				}
				console.log(e);
				this.visible = true;
			},
			close() {
				this.$refs.form.clearValidate();
				this.visible = false;
				this.formModal= {
					// fromUnitId: null,
					fileList: []
				}
			},
			onAssetsChange(assetsData) {
				if(assetsData.length !== 0) {
					this.getData(assetsData[0].code).then(()=>{
						this.$refs.form.clearValidate();
					});
				}else{
					this.assetsData = {}
				}
			}
		}
	}
</script>

<style scoped>
	.ui-box {
		width: 300px;
		padding: 10px;
		margin: 0 0 0 auto;
		background: #F5F7FA;
		border-radius: 10px;
	}
	.ui-result__item {
		margin-bottom: 0;
	}
</style>